import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Selectors";

interface StoreParams {
  user: object;
}
const INITIAL_STATE: StoreParams = {
  user: {},
};
import { getCustomerIncomeServiceTraceActions } from "./Actions";

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getCustomerIncomeServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.income = payload;
      }
    );
    builder.addCase(
      getCustomerIncomeServiceTraceActions.success,
      (state, { payload }: any) => {
        state.income = payload;
      }
    );

    builder.addCase(
      getCustomerIncomeServiceTraceActions.error,
      (state, { payload }: any) => {
        state.income = payload;
      }
    );
  },
};

const slice = createSlice(sliceOptions);

export const IncomeReducer = slice.reducer;
