import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions,
} from "@reduxjs/toolkit";
import { DataStatus } from "~/src/Lib/types/dataTransfer";
import { SLICE_NAME } from "./Selectors";
import {
  deleteNetworthDataServiceTraceActions,
  getNetworthGoldDataServiceTraceActions,
  getNetworthServiceTraceActions,
  setNetworthDataServiceTraceActions,
} from "./Actions";
import { getGoldAmount } from "~/src/Lib/utils";
import { round } from "lodash";
import { calculatePercentage } from "../Investment/Reducer";
import { NetworthModel } from "./Model";
import { TotalInvestmentsInfo } from "../Investment/Model";

export type NetworthState = {
  networth: NetworthModel;
  totalInvestments: TotalInvestmentsInfo;
  investmentsOrder?: string[];
};

export const initialState = {
  networth: {
    status: DataStatus.NOT_LOADED,
  },
  networthdata: {},
  investmentsOrder: [],
  totalInvestments: {},
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getNetworthServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.networth = {
          ...state.networth,
          ...payload,
          status: DataStatus.LOADING,
        };
      }
    );
    builder.addCase(
      getNetworthServiceTraceActions.success,
      (state, { payload }: any) => {
        state.networth = payload;
        const investmentState = {
          investments: { data: state.networth.investments },
          totalInvestments: { data: state.totalInvestments.data },
        };
        state.totalInvestments.data = calculatePercentage(investmentState);
        state.investmentsOrder = getInvestmentsSortOrder(
          state?.networth?.investments?.order || []
        );
      }
    );
    builder.addCase(
      getNetworthGoldDataServiceTraceActions.success,
      (state, { payload }: any) => {
        if (state?.networth?.investments) {
          state.networth.investments.DIGIGOLD.gold = payload;
          (state.networth.investments.totalInvested = round(
            Number.parseFloat(
              (
                Number(
                  state?.networth?.investments
                    ?.totalInvestedAmountForAllProducts
                ) + getGoldAmount(state?.networth?.investments?.DIGIGOLD) || 0
              ).toString()
            ),
            2
          )),
            (state.networth.investments.DIGIGOLD.total = round(
              getGoldAmount(state?.networth?.investments?.DIGIGOLD),
              1
            ));
          state.networth.totalNetworthBalance =
            Number(state.networth.totalBalance) +
            Number(state.networth.investments.totalInvested);
          state.networth.totalAssetsBalance =
            Number(state.networth.totalAssets) +
            Number(state.networth.investments.DIGIGOLD.total);
        }
      }
    );
    builder.addCase(
      getNetworthGoldDataServiceTraceActions.error,
      (state, { payload }: any) => {
        state.networth.investments.DIGIGOLD = payload
      })
    builder.addCase(
      getNetworthServiceTraceActions.error,
      (state, { payload }: any) => {
        state.networth = {
          ...state.networth,
          ...payload,
          status: DataStatus.ERRORED,
        };
      }
    );
    builder.addCase(
      setNetworthDataServiceTraceActions.success,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      setNetworthDataServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      setNetworthDataServiceTraceActions.error,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      deleteNetworthDataServiceTraceActions.success,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      deleteNetworthDataServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      deleteNetworthDataServiceTraceActions.error,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
  },
};

const NetworthSlice = createSlice(sliceOptions);
export const NetworthReducer = NetworthSlice.reducer;

export const getInvestmentsSortOrder = (data: string[]): string[] => {
  const investmentsArray: { [key: string]: string } = {
    deposits: "Deposits",
    mutualFund: "MF",
    digitalGold: "DIGIGOLD",
    frsb: "FRSB",
    publicProvidentFund: "PPF",
    nps: "NPS",
    sgb: "SGB",
    demat: "STOCKS",
  };

  const investmentsOrder = [] as string[];
  data?.forEach((item) => {
    const value = investmentsArray[item];
    if (value) {
      investmentsOrder.push(value);
    }
  });
  return investmentsOrder;
};
