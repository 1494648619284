import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "income";

const incomeDataSelect = (state: any) => state[SLICE_NAME];

export const getIncomeSelector = createSelector(
  incomeDataSelect,
  (incomeData: any) => incomeData
);
