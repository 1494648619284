import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "networth";

const getNetworthData = (state: any) => state[SLICE_NAME];

export const getNetworth = createSelector(getNetworthData, (data) => {
  return data;
});

export const getNetworthTotalInvestments = createSelector(getNetworthData, (data) => {
  return data?.totalInvestments;
});

export const getNetworthInvestmentOrder = createSelector(getNetworthData, (data) => {
  return data?.investmentsOrder;
});
