import { NavigateOptions, To } from "react-router-dom";

type AppRouteItem = {
  pathname: To;
  options?: NavigateOptions;
  pageName: string;
};

type AppRoute = {
  [key: string]: AppRouteItem;
};

const APP_ROUTES: AppRoute = {
  HOME: {
    pathname: "/",
    pageName: "My Money-Personal Finance Manager",
  },
  PROTECTION: {
    pathname: "/protection",
    pageName: "Your Protection Cover",
  },
  NOTIFICATION: {
    pathname: "/notifications",
    pageName: "Your Notifications",
  },
  NETWORTH: {
    pathname: "/networth",
    pageName: "My Networth",
  },
  NETWORTH_FORM: {
    pathname: "/networth/:formType",
    pageName: "My networth",
  },
  CREDIT_SCORE: {
    pathname: "/credit-score",
    pageName: "Your Credit Score",
  },
  SPEND_ANALYZER: {
    pathname: "/spend-analyzer",
    pageName: "Your Spends",
  },
  SPEND_DETAIL: {
    pathname: "/spend-analyzer/detail",
    pageName: "Spend Detail",
  },
  NO_INTERNET: {
    pathname: "/no-internet",
    pageName: "No Internet",
  },
  SOMETHING_WENT_WRONG: {
    pathname: "/something-went-wrong",
    pageName: "Something Went Wrong",
  },
  SESSION_TIMEOUT: {
    pathname: "/session-expired",
    pageName: "Session Expired",
  },
  INVESTMENT: {
    pathname: "/investment",
    pageName: "Your Investments",
  },
  FDRD_ERROR: {
    pathname: "/fd-rd-error",
    pageName: "FD RD Error",
  },
  CREDIT_LINES: { pathname: "/credit-lines", pageName: "Credit Lines" },
  CREDIT_LINES_DETAIL: {
    pathname: "/credit-lines/detail",
    pageName: "Credit Lines",
  },
  INCOME: {
    pathname: "/income",
    pageName: "Monthly Income",
  },
  ANY: {
    pathname: "*",
    pageName: "Page Not Found",
  },
};

export default APP_ROUTES;
