import traceActionsCreator from "../traceActionsCreator";

export const getCustomerIncomeServiceName = "user/getCustomerIncome";
export const getCustomerIncomeServiceTraceActions = traceActionsCreator(
  getCustomerIncomeServiceName
);

export const setCustomerIncomeServiceName = "user/setCustomerIncome";
export const setCustomerIncomeServiceTraceActions = traceActionsCreator(
  setCustomerIncomeServiceName
);
