export const APP_TITLE = process.env.APP_TITLE || "";
export const APP_DESCRIPTION = process.env.APP_DESCRIPTION || "";
export const APP_PRECONNECT_DOMAINS = process.env.APP_PRECONNECT_DOMAINS || "";
export const AS_API_DOMAIN = process.env.AS_API_DOMAIN || "";
export const AS_API_DOMAIN_DEV = process.env.AS_API_DOMAIN_DEV || "";
export const AS_API_KEY = process.env.API_KEY || "";
export const AS_ENABLE_CRPTOGRAPHY =
  process.env.AS_ENABLE_CRPTOGRAPHY === "true";
export const AS_API_TIMEOUT = parseInt(process.env.AS_API_TIMEOUT || "", 10);
export const X_API_CLIENT_ID = process.env.X_API_CLIENT_ID || "BROWSER";
export const DISABLE_SPENDS_UI = process.env.DISABLE_SPENDS_UI === "true";
export const APP_ENV = process.env.APP_ENV;
export const ENABLE_CHANAKYA_WIDGET =
  process.env.ENABLE_CHANAKYA_WIDGET === "true";
export const DOWNTIME_MESSAGE = process.env.DOWNTIME_MESSAGE || "";
export const DISABLE_STRICT_MODE_TEMP =
  process.env.DISABLE_STRICT_MODE_TEMP === "true";
export const INVESTMENT_DATA_DOWNTIME_MESSAGE =
  process.env.INVESTMENT_DATA_DOWNTIME_MESSAGE || "";
export const IS_INVESTMENT_API_DOWN =
  process.env.IS_INVESTMENT_API_DOWN === "true";
