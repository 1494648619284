import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  getTheme,
  DsCssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider, DsRemixIcon,
  DsIconButton
} from '@am92/react-design-system'
import { SnackbarProvider, closeSnackbar } from 'notistack';

import {
  getAccessTokenSelector,
  getRefreshTokenSelector,
} from "./Redux/Auth/Selectors";

import { asHttp } from "./Configurations/WebHttp";
import getAppRouter from "~/src/Configurations/getAppRouter";
import performHandshake from "~/src/Services/performHandshake";

import {
  PALETTE,
  FONT_FAMILY,
  THEME_MODE_STORAGE_KEY,
  DEFAULT_THEME_MODE
} from '~/src/Constants/THEME'
import { OverlayLoader } from './Components/OverlayLoader'
import { WEB_HTTP_CONTEXT, WEB_HTTP_REQ_HEADERS } from "@am92/web-http";
import Onboarding from './Pages/Onboarding/Onboarding'
import { RouterProvider } from "react-router-dom";


type Props = {
  persisted: boolean;
  accessToken?: string;
  refreshToken?: string;
};

type State = {
  hasError: boolean;
  chId: string;
  id: string;
  name: string;
  isLoading: boolean;
  handshakeComplete: boolean;
}


const DefaultState: State = {
  hasError: false,
  chId: "",
  id: "",
  name: "",
  isLoading: false,
  handshakeComplete: false,
};
class App extends Component<Props, State> {
  [x: string]: any;
  state = DefaultState;

  constructor(props: Props) {
    super(props);
    // If you dont want to respect user selected theme
    // and set default theme to one set in THEME constants then uncomment the line
    // this.resetUserThemeToDefault()
  }
  componentDidMount() {
    this.initialize();

    // !this.hasError ? console.log("loaded") : console.log("not loaded");
  }


  initialize = async () => {
    this.setState({ isLoading: true });
    try {
      await performHandshake()
      this.setState({ handshakeComplete: true, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false, hasError: true });
    }
  };

  resetUserThemeToDefault = () => {
    window.localStorage.removeItem(THEME_MODE_STORAGE_KEY);
  };

  render() {

    let children = <OverlayLoader />
    const AppTheme = getTheme(PALETTE, FONT_FAMILY)
    const { isLoading, hasError, handshakeComplete } = this.state;
    const { persisted, accessToken, refreshToken } = this.props;

    if (persisted && handshakeComplete && !isLoading) {
      if (!this.router) {
        this.router = getAppRouter();
      }
      children = <RouterProvider router={this.router} />;

      if (accessToken && refreshToken) {
        asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
        asHttp.context.set(
          WEB_HTTP_CONTEXT.AUTHENTICATION_TOKEN_KEY,
          WEB_HTTP_REQ_HEADERS.AUTH_TOKEN
        );
        asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken);
      }
    }

    return (
      <CssVarsProvider
        theme={AppTheme}
        defaultMode={DEFAULT_THEME_MODE}
        modeStorageKey={THEME_MODE_STORAGE_KEY}
      ><SnackbarProvider
        style={{ background: '#165964' }}
        iconVariant={{
          default: (
            <DsRemixIcon
              className='ri-information-line'
              sx={{
                color: 'white',
                paddingRight: '1rem',
                marginLeft: '-0.5rem'
              }}
            />
          )
        }}
        action={(key) => (
          <DsIconButton onClick={() => closeSnackbar(key)}>
            <DsRemixIcon className='ri-close-line' sx={{ color: 'white' }} />
          </DsIconButton>
        )}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
          <DsCssBaseline enableColorScheme>
            <Suspense fallback={isLoading ? <OverlayLoader /> : null}>
              {children}
            </Suspense>
          </DsCssBaseline>
          <Onboarding />
        </SnackbarProvider>
      </CssVarsProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  const accessToken = getAccessTokenSelector(state);
  const refreshToken = getRefreshTokenSelector(state);

  return {
    accessToken,
    refreshToken,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
